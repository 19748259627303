import React from "react";
import {Link} from "gatsby";
import Container from "../container/Container";
import "./style.css";

const BlogPosts = ({posts}) => {
	return (
		<Container size="xsmall">
			<div className="blog-posts">
				{posts.map(post => {
					const title = post.frontmatter.title || post.fields.slug;
					const images = (post.frontmatter.image && post.frontmatter.image.split(',')) || [];
					
					return (
						<div key={post.fields.slug} className="blog-posts-item">
							<Link to={post.fields.slug} itemProp="url">
								<article
									className="post-list-item"
									itemScope
									itemType="http://schema.org/Article"
								>
									<div className="post-article-inner">
										<div>
											<header>
												<h1 className="blog-post-title">
													{title}
												</h1>
												<div className="blog-post-category">{post.frontmatter.category.toUpperCase()}</div>
												<small className="blog-post-date">{post.frontmatter.date} - 8 MIN READ</small>
											</header>
											<section>
												<p
													dangerouslySetInnerHTML={{
														__html: post.frontmatter.description || post.excerpt,
													}}
													itemProp="description"
													className="blog-post-summary"
												/>
											</section>
										</div>
										
										{ images && images.length ? (
											<section className="blog-images-wrapper">
												{ images.map((image) => <img src={`/assets/${image}`} alt={image} />) }
											</section>
										) : null }
									</div>
								</article>
							</Link>
						</div>
					)
				})}
			</div>
		</Container>
	);
}

export default BlogPosts;
