import React from "react";
import Container from "../../container/Container";
import { Row, Col } from "reactstrap";
import Title from "../../../components/typography/Title";
import Subtitle from "../../../components/typography/Subtitle";
import classNames from "classnames";
import "./styles.css";

const Heading = ({
  title,
  subtitle,
  big,
  children,
}) => {
  return (
    <>
      <header className={classNames("blog-header", {
        'big': big,
      })}>
        <Container size="small">
          <Row>
            <Col xl="12">
              <Title
                align="text-center"
                size="large"
                invertColor={true}
              >{ title }</Title>

              <Subtitle
                size="medium"
                align="text-center"
                invertColor={true}>
                { subtitle }
              </Subtitle>
            </Col>
          </Row>
          {children}
        </Container>
      </header>
    </>
  );
};

export default Heading;
