import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Header from "../components/headers/blog/Header";
import Navigator from "../components/navigator/DefaultNavbar";
import Footer from "../components/footer/DefaultFooter";
import CookieConsent from "../components/cookieConsent/CookieConsent";
import BlogPosts from "../components/blog/BlogPosts";
import { useQueryParam } from "gatsby-query-params";
import { Link } from "gatsby";

const categories = ['All', 'Tutorials', 'General'];

const IndexPage = ({ data }) => {
    const posts = data.allMarkdownRemark.nodes;
    const activeCategory = useQueryParam('category', 'all');

    return (
        <>
            <SEO
                title="Blog"
                description="Blog" />
            <Navigator
                useSpaNav={true}
            />
            <Header
                big={false}
                title="Mockless Blog"
                subtitle="Tutorials, announcements and use cases for Mockless.">
                <div className={`blog-categories-wrapper`}>
                    {categories.map(categoryName => (
                        <Link to={categoryName.toLowerCase() === 'all' ? '/blog' : `?category=${categoryName.toLowerCase()}`}>
                            <span className={`blog-category ${activeCategory === categoryName.toLowerCase() ? 'blog-category-active' : ''}`}>
                                {categoryName}
                            </span>
                        </Link>
                    ))}
                </div>
            </Header>

            <BlogPosts posts={posts.filter(({frontmatter: {category}}) => activeCategory === 'all' || category.toLowerCase() === activeCategory)} />

            <div style={{ marginTop: 200 }}>
                <Footer />
            </div>
            <CookieConsent />
        </>
    )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          image
          category
        }
      }
    }
  }
`
