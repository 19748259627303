import * as React from 'react';
import classNames from 'classnames';
import "./styles.css";

export default function({
  size = 'normal',
  invertColor,
  children,
  align,
  lifetime,
}) {
  const classes = classNames('title-component', {
    'invert-color': invertColor,
    [align]: !!align,
    'lifetime': lifetime,
  });

  if (size === 'large') {
    return <h1 className={classes}>{children}</h1>
  }

  if (size === 'normal') {
    return <h3 className={classes}>{children}</h3>
  }

  if (size === 'small') {
    return <h5 className={classes}>{children}</h5>
  }

  if (size === 'xsmall') {
    return <div className={classes}>{children}</div>
  }
}
