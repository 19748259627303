import * as React from 'react';
import classNames from 'classnames';
import "./styles.css";

export default function({
  size = 'normal',
  invertColor,
  children,
  align,
}) {
  if (size === 'large') {
    return <h4 className={classNames('subtitle-component', {
      'invert-color': invertColor,
      [align]: !!align,
      [size]: size,
    })}>{children}</h4>
  }

  if (size === 'medium') {
    return <h3 className={classNames('subtitle-component', {
      'invert-color': invertColor,
      [align]: !!align,
      [size]: size,
    })}>{children}</h3>
  }

  if (size === 'small') {
    return <span className={classNames('subtitle-component', {
      'invert-color': invertColor,
      [align]: !!align,
      [size]: size,
    })}>{children}</span>
  }

  return <span className={classNames('subtitle-component', size, {
    'invert-color': invertColor,
    [align]: !!align,
  })}>{children}</span>
}
